.product-card {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  font-weight: 400;
  cursor: pointer;
}

.product-card:hover {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
