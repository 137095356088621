.show-purchase-order {
  border: 1px solid #ccc;
  padding: 3rem 3rem;
  border-radius: 0.5rem;
}

.show-purchase-order--totals td {
  padding-top: 1rem;
  font-weight: bold;
}

.show-purchase-order--updatable-input {
  transition: background-color 0.3s;
}