.custom-alert {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 0.9;
  transition: opacity 0.3s ease-in-out;
}

.custom-alert.green {
  background-color: #28a745;
}

.custom-alert.red {
  background-color: #f44336;;
}