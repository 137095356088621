a {
  cursor: pointer;
}

textarea, select, input {
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
table tr:last-of-type td {
  border-bottom: none;
}

.container.padded {
  padding: 30px;
}

table.compact td {
  padding: 2px;
  vertical-align: top;
}

table.border {
  border: 1px solid #ccc;
}

table.padded {
  padding: 30px;
}

.center-middle {
  display: flex;
  justify-content: center; /* Aligns horizontally */
  align-items: center;    /* Aligns vertically */
  height: 100vh;          /* Full viewport height */
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}

.autocomplete {
  position: relative;
}

.autocomplete ul {
  position: absolute;
  width: 100%;
  top: 40px;
  list-style: none;
  margin: 0;
  background-color: #FFF;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 1px 2px 8px 1px rgba(0, 0, 0, 0.3);
  max-height: 225px;
  overflow-y: auto;
  z-index: 1000;
}

.autocomplete ul li {
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #CCC;
  margin: 0;
}

.autocomplete ul li:hover {
  background-color: #f9f9f9;
}

.autocomplete ul li:last-child {
  border-bottom: none;
}

.fixedWidth-lg {
  width: 200px;
}

legend {
  font-size: 1.9rem;
  color: var(--primary-color);
}