.navbar {
  border-bottom: 1px solid var(--primary-color);
}
.navigation {
  padding: 10px 0;
}

.navigation a {
  margin-right:20px;
  font-weight: 500;
}

.hamburger {
  display: none;
}

.staging {
  border-top: 27px solid #FFF;
}

.staging:after {
  content: 'Development';
  position: absolute;
  color: white;
  background-color: #010101;
  top: 1px;
  left: 5px;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 600;
}

.staging:before {
  content: ' ';
  height: 29px;
  position: absolute;
  top: 0;
  background: repeating-linear-gradient(45deg, #ffcc00, #ffcc00 10px, #000000 10px, #000000 20px);
  width: 100%;
}


.staging:after {
  content: 'Staging';
}

/* Media Query for screen width less than 900px */
@media (max-width: 950px) {
  .navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .navigation a {
    margin: 10px 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 30px;
  }

  .links {
    display: none;
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    background-color: #FFF;
    z-index: 1;
    right: 0;
    top: 45px;
    padding: 0 50px 0 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .links.active {
    display: flex;
  }

}