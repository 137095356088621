ol.breadcrumb {
  padding: 0.75rem 0;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
}

ol.breadcrumb li {
  display: inline;
  font-size: 1.3rem;
}

ol.breadcrumb li + li::before {
  content: ">";
  margin: 0 5px;
}